import Vue from 'vue'
import App from './App.vue'
import router from './router'

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    var token = localStorage.getItem('access_token')
    if (!token) {
      next({
        path: '/login',
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.onAuth)) {
    var token = localStorage.getItem('access_token')
    if (token) {
      next({
        path: '/dashboard',
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

Vue.mixin({
  methods: {
    openNotification(position = null, color, duration, title, message) {
      this.$vs.notification({
        color,
        position,
        duration,
        title: title,
        text: message,
      })
    },
  },
})

//Vuesax
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
Vue.use(Vuesax)

// Bootstrap Vue
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
// Vue.use(BootstrapVue)
// Vue.use(IconsPlugin)

// Vue Material
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
Vue.use(VueMaterial)

// QR Code Reader
import VueQrcodeReader from 'vue-qrcode-reader'
Vue.use(VueQrcodeReader)

import axios from 'axios'
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api/'
axios.defaults.baseURL = 'https://my.qrcbn.com/api/'

var AUTH_TOKEN = localStorage.getItem('access_token');
if (AUTH_TOKEN){
  axios.defaults.headers.common['Authorization'] = 'Bearer '+AUTH_TOKEN;
}

import VueHtml2Canvas from 'vue-html2canvas'
Vue.use(VueHtml2Canvas)

// Adsense
import Ads from 'vue-google-adsense'
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)

import VueAgile from 'vue-agile'
import vuetify from './plugins/vuetify'
Vue.use(VueAgile)

import VueCurrencyFilter from 'vue-currency-filter'
Vue.use(VueCurrencyFilter, {
  symbol: 'Rp.',
  thousandsSeparator: ',',
  fractionCount: 0,
  fractionSeparator: ',',
  symbolPosition: 'front',
  symbolSpacing: true,
  avoidEmptyDecimals: undefined,
})


Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
